.navbar.navbar-light.inline {
  padding: 0;
  background: $content-bg-color !important;

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
  }

  input {
    box-shadow: none;
  }

  .control-search {
    width: 100%;
  }

  & > * {
    min-height: 100%;
  }

  &:first-child {
    border-top-left-radius: $default-border-radius;
    border-top-right-radius: $default-border-radius;
  }

  &:last-child {
    border-bottom-left-radius: $default-border-radius;
    border-bottom-right-radius: $default-border-radius;
  }

  &:not(:first-child) {
    border-top: 2px solid #f7f7f7;
  }

  @include media-breakpoint-down(sm) {
    & > * {
      flex: 1;
      width: 100%;
      padding: $ui-padding / 2;
      &:not(:first-child) {
        border-top: 2px solid #f7f7f7;
      }
    }
  }

  @include media-breakpoint-up(md) {
    & > * {
      padding: $ui-padding / 2;
      &:not(:first-child) {
        border-left: 2px solid #f7f7f7;
      }
    }
  }
}
