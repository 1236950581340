$hamburger-layer-color: map-get($theme-colors, 'dark');
$hamburger-padding-y: 5px;
$hamburger-layer-width: 32px;
$hamburger-layer-height: 3px;
$hamburger-hover-opacity: 1;

$hamburger-padding-x: 0.5rem;
$hamburger-padding-y: 2.157rem;
$hamburger-layer-width: 28px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 7px;
$hamburger-layer-color: map-get($theme-colors, 'dark');

@import './hamburgers/hamburgers.scss';

#site-menu {
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.1);
  min-height: 92px;
  max-height: 100%;
  @include media-breakpoint-down(md) {
    &.open {
      overflow-y: scroll;
    }
  }

  background-color: #fff;

  .menu-item > a {
    line-height: 1;
    font-size: 0.875rem;
  }

  @include media-breakpoint-up(lg) {
    & {
      padding: 0;
    }
    .primary-menu-item {
      color: map-get($theme-colors, 'dark') !important;
      padding: 2.25rem 1.3rem;
      position: relative;
      &:hover {
        &:before {
          background-color: map-get($theme-colors, 'primary');
          position: absolute;
          width: 100%;
          height: 3px;
          content: '';
          left: 0;
          top: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    & {
      padding: 0 1.2rem;
    }
    .collapse-wrapper {
      width: 100%;
    }

    .navbar-collapse {
      .nav-item {
        text-transform: uppercase;
        font-weight: 700;
        width: 100%;
      }

      .navbar-nav {
        justify-content: space-around;
        flex-direction: column;
        display: flex;
        height: 100%;
        flex: 1;

        .ponny-here {
          display: none;
          width: 75%;
          margin: 0 auto;
        }
      }
    }

    &.open {
      background-color: map-get($theme-colors, 'dark');
      .navbar-brand svg.logo .cls-1 {
        fill: #fff;
      }
      .hamburger-inner {
        background-color: #fff !important;
        &:before,
        &:after {
          background-color: #fff !important;
        }
      }
      .btn-outline-primary,
      .locale-switch > .btn,
      .primary-menu-item {
        color: #fff !important;
      }
    }
  }
}

#site-menu-placeholder {
  display: block;
  height: 92px;
  width: 100%;
}

.locale-switch {
  & > .btn,
  & > .btn:hover {
    color: map-get($theme-colors, 'dark') !important;
    background: transparent !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    position: relative !important;
    overflow: visible !important;
    box-shadow: none !important;
    border-radius: !important;
    margin: 8px 0 !important;
    border: none !important;

    &:after {
      display: none;
    }

    & > .icon {
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      bottom: -5px;
      &:before {
        color: map-get($theme-colors, 'primary');
        margin: 0 !important;
      }
    }
  }

  .dropdown-menu {
    min-width: auto;
  }
}

.navbar-toggler {
  border-width: 0;
  padding: 0;
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
  max-height: none !important;
}
