.pricing-cards {
  display: flex;
  justify-content: center;

  .card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    & > p {
      line-height: 2;
    }
  }

  @include media-breakpoint-down(sm) {
    & {
      flex-direction: column;
    }

    .card {
      margin-bottom: $grid-gutter-width;
    }
  }

  @include media-breakpoint-up(md) {
    .card {
      min-width: 250px;
      max-width: 350px;
      width: 30%;
    }

    .card:nth-child(2) {
      margin-left: -1.5 * $large-border-radius;
      margin-right: -1.5 * $large-border-radius;
      z-index: 2;
    }

    .card:nth-child(1) {
      margin-top: 2 * $large-border-radius !important;
      margin-bottom: 2 * $large-border-radius !important;
      padding-right: 2.5 * $large-border-radius !important;
      z-index: 1;
    }

    .card:nth-child(3) {
      margin-top: 2 * $large-border-radius !important;
      margin-bottom: 2 * $large-border-radius !important;
      padding-left: 2.5 * $large-border-radius !important;
      z-index: 1;
    }
  }
}

.card {
  .price {
    margin: 0;
    position: relative;

    sup {
      color: map-get($theme-colors, 'success');
      display: inline-block;
      position: absolute;
      text-align: center;
      font-size: 0.85rem;
      font-weight: bold;
      line-height: 1;
      top: -0.85rem;
      width: 150%;
      left: -25%;
    }
  }
}
