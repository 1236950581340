.cooperation-page {
  @include media-breakpoint-down(sm) {
    .home-jumbo-1-bg,
    .home-jumbo-1-curve {
      display: none;
    }

    .home-jumbo-1:after {
      right: 0;
    }
  }
  .cooperation-page__header {
    & > h1 {
      max-width: 40%;
    }

    & > p {
      max-width: 45%;
    }

    @include media-breakpoint-down(md) {
      & > p {
        max-width: 100%;
      }

      & > h1 {
        max-width: none;
      }
    }
  }

  .cooperation-page__content {
    position: relative;
    margin-top: -6rem;
    padding-top: 0;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      margin-top: -4rem;
    }

    @include media-breakpoint-down(md) {
      margin-top: -2rem;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }
}
