.cooperation-input {
  & > label {
    padding: 0.5rem 1rem;
    margin: 0;
  }

  .dropdown.empty {
    .dropdown-menu {
      display: none !important;
    }
  }

  .badge.close {
    padding-right: 2rem;
    position: relative;
    float: left;

    [class^='icon-'],
    [class*=' icon-'] {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      justify-content: center;
      align-items: center;
      position: absolute;
      cursor: pointer;
      display: flex;
      width: 1.5rem;
      bottom: 0;
      right: 0;
      top: 0;
    }
  }
}
