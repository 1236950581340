.layout-contact {
  .layout-contact__wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    padding: $ui-padding * 2 $ui-padding;
    margin: $ui-padding * 4 auto;

    & > * {
      display: flex;
      padding: $ui-padding * 3 $ui-padding * 2;
      z-index: 1;
      position: relative;
    }

    &::before {
      background: $content-bg-color;
      position: absolute;
      content: '';
      z-index: 0;
      width: 90%;
      bottom: 0;
      right: 0;
      top: 0;
    }

    .layout-contact__info {
      background: map-get($theme-colors, 'primary');
      max-width: 400px;
      width: 40%;
      display: flex;
      flex-direction: column;
      color: #fff;

      [class^='icon-'],
      [class*=' icon-'] {
        &::before {
          color: #fff !important;
        }
      }

      .layout-contact__icons {
        [class^='icon-'],
        [class*=' icon-'] {
          font-size: 1.5rem;
        }
      }

      .layout-contact__map {
        position: relative;
        padding-bottom: 60%;

        & > iframe {
          width: 100% !important;
          height: 100% !important;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .layout-contact__content {
      flex: 1;
      padding: $ui-padding * 2 $ui-padding * 4;

      .form-control {
        border-width: 0px;
        border-bottom: 1px solid $gray-color;
        border-radius: 0 !important;
        box-shadow: none;

        &.is-invalid {
          border-bottom: 2px solid map-get($theme-colors, 'danger') !important;
          margin-bottom: -1px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      & > * {
        width: 100% !important;
        max-width: 100% !important;
        position: relative;
      }
      &::before {
        width: 100%;
      }
      flex-direction: column;
      margin: $ui-padding 0;
      & > .layout-contact__content {
        padding: $ui-padding * 2 $ui-padding;
      }
    }
  }
}
