@import '~bootstrap/scss/mixins/_breakpoints';

footer.page-footer {
  border-top: 1px solid map-get($theme-colors, 'dark');
  background: map-get($theme-colors, 'dark');
  justify-content: space-around;
  color: #fff !important;
  flex-direction: row;
  padding: $spacer 0;
  padding: 2rem 0;
  display: flex;
  width: 100%;
  flex: 0;

  & a {
    color: #fff !important;
    font-size: 0.8rem;
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
    &:hover {
      color: #757575 !important;
    }
  }

  .copyright {
    a {
      color: #757575 !important;
    }
  }

  @include media-breakpoint-down(sm) {
    & {
      flex-direction: column;
    }
    a {
      text-align: center;
    }
  }
}
