.layout-auth {
  flex-direction: column;
  display: flex;
  flex: 1;

  .layout-auth__container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;
    flex: 1;

    .layout-auth__box {
      flex-direction: column;
      padding: $spacer * 2;
      text-align: center;
      position: relative;
      display: flex;
    }
  }

  @include media-breakpoint-up(sm) {
    .layout-auth__box {
      min-width: 450px;
    }
  }
  @include media-breakpoint-down(sm) {
    .layout-auth__box {
      min-width: 100%;
      flex: 1;
    }
    & {
      justify-content: flex-start;
    }
  }
}
