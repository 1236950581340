.form-conrol.file {
  .file-item {
    border-radius: $default-border-radius;
    background: map-get($theme-colors, 'white');
    padding: 0.5rem 1rem;
    height: 45px;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .file-list {
    margin-bottom: 1rem;
  }
}
