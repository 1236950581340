$jumbo-left: 40%;
$content-bg-color: #f4f8ff;

.home-page {
  background: #fff;
  position: relative;
  & > * {
    position: relative;
    z-index: 1;
  }

  .home-content {
    position: relative;
    overflow: hidden;
    margin-top: 5rem;

    .layout-common {
      padding-top: 0;
    }

    #home-bg {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;
      left: 0;
      top: 0;

      &:before {
        background-image: linear-gradient(
          to right top,
          $content-bg-color 0%,
          $content-bg-color 50%,
          transparent 50%
        );
        position: absolute;
        content: '';
        height: 40%;
        width: 150%;
        top: 0;
        left: 0;
      }
      &:after {
        background-image: linear-gradient(
          to right bottom,
          $content-bg-color 0%,
          $content-bg-color 50%,
          transparent 50%
        );
        position: absolute;
        content: '';
        height: 40%;
        width: 150%;
        top: 40%;
        left: 0;
      }
    }
  }

  .home-s4 {
    margin-top: 6rem;

    .home-s4-cards {
      display: flex;
      flex-direction: row;

      .card:first-child {
        z-index: 3;
      }

      @include media-breakpoint-up(md) {
        .card {
          &:first-child {
            padding-bottom: 2rem !important;
            padding-top: 2rem !important;
            width: 50%;
          }

          width: 30%;
          display: inline-block;
          &:nth-child(n + 2) {
            margin-left: -$large-border-radius;
            margin-bottom: 2rem !important;
            padding-left: 4rem !important;
            border-bottom-left-radius: 0;
            margin-top: 2rem !important;
            border-top-left-radius: 0;
            border-color: #e7e7e7;
            border-width: 2px;
          }

          &:last-child {
            border-bottom-right-radius: 0 !important;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        .card {
          width: 100% !important;
        }

        .card:nth-child(n + 2) {
          margin-top: -1.5 * $large-border-radius;
          padding-top: 4rem !important;
          border-color: #e7e7e7;
          background: #fff;
          border-width: 2px;
        }

        .card:nth-child(2) {
          z-index: 2;
        }
      }
    }
  }

  .footer {
    margin-top: 3rem;
    position: relative;
    & > .container > img {
      width: 70% !important;
      max-width: 600px;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      background: map-get($theme-colors, 'dark');
      height: 45%;
      z-index: -1;
      left: 0;
    }
  }
}

// used only in cooperation pages now
.home-jumbo-1 {
  background-size: contain;
  box-sizing: border-box;
  position: relative;
  min-height: 500px;
  overflow: hidden;
  border-radius: 0;

  display: flex;
  align-items: center;

  @include media-breakpoint-down(sm) {
    background-size: cover;
  }

  & > * {
    position: relative;
    z-index: 1;
  }

  .home-jumbo-1-curve {
    position: absolute;
    left: $jumbo-left;
    margin-left: -1px;
    bottom: -1px;
    height: 101%;
    top: -3px;

    path {
      fill: $body-bg;
    }
  }

  // TODO: move to content pages
  .home-jumbo-1-bg {
    background-color: $content-bg-color;
    background-repeat: no-repeat;
    background-size: auto 100%;
    margin-bottom: -1px;
    position: absolute;
    left: $jumbo-left;
    display: block;
    bottom: 1px;
    right: 0;
    top: 0;

    video {
      height: 100%;
    }
  }

  &:after {
    background: $body-bg;
    right: 100 - $jumbo-left;
    position: absolute;
    padding-right: 2rem;
    bottom: -4rem;
    z-index: 0;
    left: -2rem;
    content: '';
    top: -4rem;
  }

  @include media-breakpoint-down(md) {
    .home-jumbo-1-curve,
    .home-jumbo-1-bg {
      left: 60%;
    }

    &:after {
      right: 100 - 60%;
    }
  }

  @include media-breakpoint-down(xs) {
    .home-jumbo-1-curve,
    .home-jumbo-1-bg {
      left: 40%;
    }

    &:after {
      right: 100 - 40%;
    }
  }
}

.home-s-1 {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  position: relative;
  border-radius: 0;

  .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    display: flex;
    position: relative;
    z-index: 1;
    h1 {
      font-weight: 700;
      font-size: 4.5rem;

      @include media-breakpoint-down(lg) {
        font-size: 3.5rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 2.5rem;
      }
    }
  }

  .home-s-1-img {
    display: block;
    width: 100%;
    background-image: url('_assets/images/home-header.svg');
    background-position: bottom center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: auto 80%;

    position: relative;
    padding-bottom: 45%;
    margin-top: -20%;

    @include media-breakpoint-down(lg) {
      margin-top: -10%;
    }

    .home-s-1-pin {
      transform-origin: bottom center;
      flex-direction: column;
      align-items: center;
      position: absolute;
      display: flex;
      z-index: 0;

      @include media-breakpoint-down(xs) {
        transform: scale(0.7);
      }

      & > span {
        display: block;
        z-index: 0;

        & > i {
          position: absolute;
          border-radius: 100%;
          height: 1rem;
          width: 1rem;
          line-height: 0.75rem;
          font-size: 0.75rem;
          text-align: center;
          background: map-get($theme-colors, 'primary');
          color: map-get($theme-colors, 'white');
          padding: 0.125rem;
          z-index: 2;
          font-style: normal;
        }
        // rotated block
        & > span {
          border-radius: $default-border-radius * 2;
          transform-origin: bottom left;
          transform: rotate(45deg);
          position: relative;
          overflow: hidden;
          display: block;
          margin-top: -20%;
          // image
          & > span {
            background-position: center center;
            background-repeat: no-repeat;
            transform-origin: top left;
            transform: rotate(-45deg);
            background-size: cover;
            margin-left: -50%;
            margin-top: 50%;
            display: block;
          }
        }
      }
      // dots
      & > i {
        background: map-get($theme-colors, 'primary');
        border-radius: 100%;
        margin-top: 0.65rem;
        height: 0.5rem;
        display: block;
        width: 0.5rem;
      }
    }
  }
}

.home-s2 {
  margin-top: 0 !important;
  .card.dark {
    background: map-get($theme-colors, 'dark');
    color: #fff !important;
  }

  .card {
    border-radius: $extra-border-radius;
  }

  & > div {
    &:first-child > .card {
      border-bottom-left-radius: 0;
    }
    &:last-child > .card {
      border-bottom-right-radius: 0;
    }
  }

  @include media-breakpoint-up(md) {
    & > div:last-child .card {
      margin-top: 6rem !important;
    }
  }

  .card h2 {
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    position: relative;

    &:after {
      background: map-get($theme-colors, 'primary');
      position: absolute;
      border-radius: 2px;
      max-width: 100%;
      bottom: -2px;
      height: 4px;
      width: 5rem;
      content: '';
      left: 0;
    }
  }
}

.home-s-3 {
  margin-top: 6rem;

  .cooperation-type {
    padding-right: 3rem;
    padding-bottom: 3rem;
  }
}

.home-s5 {
  margin-top: 6rem;
}

.quotes {
  .quote-card {
    background: map-get($theme-colors, $white);
    border-radius: $large-border-radius * 0.75;
    border: 1px solid $gray-color;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 750px;
    margin: 0 auto;
    display: flex;

    span.icon {
      color: map-get($theme-colors, 'primary') !important;
      position: absolute;
      right: 3rem;
      top: -2rem;
    }

    h5 {
      font-size: 1.2rem;
      font-weight: bold;
    }

    blockquote {
      font-size: 1.4rem;
      line-height: 1.6;
      font-weight: 300;
      max-width: 420px;
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
  }

  .icon.icon-arrow-left,
  .icon.icon-arrow-right {
    color: $base-font-color !important;
    background-color: #f4f8ff;
    border-radius: 100%;
    position: absolute;
    padding: 15px;
    opacity: 1;
  }

  .icon.icon-arrow-left {
    left: 0;
  }

  .icon.icon-arrow-right {
    right: 0;
  }

  .carousel-control-next {
    background: -moz-linear-gradient(left, hsla(0, 0%, 100%, 0) 30%, hsla(0, 0%, 100%, 1) 100%);
    background: -webkit-linear-gradient(left, hsla(0, 0%, 100%, 0) 30%, hsla(0, 0%, 100%, 1) 100%);
    background: linear-gradient(to right, hsla(0, 0%, 100%, 0) 30%, hsla(0, 0%, 100%, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
  }

  .carousel-control-prev {
    background: -moz-linear-gradient(
      left,
      hsla(0, 0%, 100%, 1) 0%,
      hsla(0, 0%, 100%, 0) 70%,
      hsla(0, 0%, 100%, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      hsla(0, 0%, 100%, 1) 0%,
      hsla(0, 0%, 100%, 0) 70%,
      hsla(0, 0%, 100%, 0) 100%
    );
    background: linear-gradient(
      to right,
      hsla(0, 0%, 100%, 1) 0%,
      hsla(0, 0%, 100%, 0) 70%,
      hsla(0, 0%, 100%, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }

  .carousel-indicators {
    margin-right: auto;
    margin-left: auto;

    li {
      background-color: #dfe2e8;
      border-radius: 100%;
      height: 0.5rem;
      width: 0.5rem;

      &.active {
        background-color: map-get($theme-colors, 'primary');
      }
    }
  }

  .carousel-inner {
    margin-bottom: 2rem;
  }
}
