$body-bg: #f7f7f7;
$content-bg-color: #fff;
$gray-color: #e7e7e7;
$base-font-color: #212529;
$base-p-color: #606060;

$theme-colors: (
  'secondary': #606060,
  'primary': #473cef,
  'success': #8bc34a,
  'warning': #ffc107,
  'danger': #f44336,
  'white': #ffffff,
  'dark': #212529,
  'info': #9ae1ff,
  'gray': $gray-color
);

$default-shadow: 0 0 0.625rem rgba(11, 57, 84, 0.05);
$default-border-radius: 0.375rem;
$large-border-radius: 2rem;
$extra-border-radius: 4rem;
$ui-padding: 1.25rem;
$grid-gutter-width: $ui-padding * 2;
$font-weight-normal: 300;
$font-weight-bold: 400;

@import '~bootstrap/scss/bootstrap.scss';

@import '_components/Menu/menu';
@import '_components/Layout/auth';
@import '_components/Layout/common';
@import '_components/Footer/footer';
@import '_components/Layout/contact';
@import '_components/Form/_components/FileInput/file';
@import '_components/Form/_components/ImageInput/image';

@import '_pages/_components/PricingCards/pricing-cards';

@import '_pages/HomePage/home';
@import '_pages/Error/error-layout';
@import '_pages/_components/inline-panel';
@import '_pages/ContentPage/CooperationPage/cooperation';
@import '_pages/_components/SearchDealsFilter/search-deals-filter';

@import url('https://fonts.googleapis.com/css?family=Rubik: 400,500,700&subset=latin-ext');

/*
--------------------- Base ---------------------
*/
html,
body,
#root {
  font-family: 'Rubik', sans-serif;
  font-weight: 300 !important;
  color: $base-font-color;
  flex-direction: column;
  font-weight: 400;
  min-height: 100%;
  font-size: 16px;
  min-width: 100%;
  display: flex;
  flex: 1;
  max-width: 100vw;
  overflow-x: hidden;
}

h1 {
  font-size: 32px;
}

h1,
h2,
h3,
h4,
h5 {
  display: inline-block;
  font-weight: 400;
  width: auto;
}

p {
  color: $base-p-color;
  overflow-x: hidden;
  text-align: left;
  max-width: 100%;
}

dl {
  overflow-x: hidden;
  max-width: 100%;
}

h2.page-heading {
  font-weight: 700;
  font-size: 3rem;
}

h3.page-heading {
  font-weight: 500;
  line-height: 1.6;
}

h3 {
  font-size: 1.5rem;
}

.text-small {
  font-size: 0.75rem;
}

.text-medium {
  font-size: 0.875rem;
}

.text-bolder {
  font-weight: 500;
}

.text-justify {
  text-align: justify;
}

hr {
  background: map-get($theme-colors, 'primary');
  border-radius: 2px;
  margin-right: 0;
  margin-left: 0;
  display: block;
  width: 5rem;
  height: 4px;
}

strong,
.text-strong {
  font-weight: 700;
}

.invalid-feedback {
  display: inline-block;
}

.card {
  border-width: 0;
  border-radius: $large-border-radius;
  @extend .shadow;
}

.text-center {
  text-align: center;
}

.text-thin {
  font-weight: 300;
}

.bg-primary-light {
  background: rgba(map-get($theme-colors, 'primary'), 0.2);
}

@include media-breakpoint-down(sm) {
  .img-sm-fluid {
    max-width: 100%;
  }
}

/*
--------------------- Buttons ---------------------
*/
.btn {
  justify-content: center;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  align-items: center;
  font-size: 0.875rem;
  border-radius: 2rem;
  text-align: center;
  padding: 0.782rem 3rem;

  overflow: hidden;

  /* sole icon - solo stand icon = content not use asbtn bg */
  & > *.icon:not(.sole-icon) {
    display: inline-block !important;
    margin-right: 0 !important;
    &:before {
      margin-left: -2rem;
    }
  }

  &.btn-block {
    text-align: center;
    padding-right: 0;
    padding-left: 0;
    display: block;
    width: 100%;
  }

  &.btn-sm > *.icon:not(.sole-icon) {
    &:before {
      margin-left: -1.5rem;
    }
  }

  &.btn-icon {
    padding: 0.5rem 1rem;
    & > .icon:before {
      margin-left: 0;
    }
  }

  &.btn-outline-gray {
    color: map-get($theme-colors, 'dark');
  }

  &.btn-sm {
    padding: 0.4rem 2rem;
  }

  &.btn-lg {
    padding: 1rem 2rem;
  }

  $button-shadow: 5px;

  &.btn-primary:hover,
  &.btn-outline-primary:hover {
    box-shadow: 0 0 $button-shadow map-get($theme-colors, 'primary');
  }

  &.btn-secondary:hover,
  &.btn-outline-secondary:hover {
    box-shadow: 0 0 $button-shadow map-get($theme-colors, 'secondary');
  }

  &.btn-success:hover,
  &.btn-outline-success:hover {
    box-shadow: 0 0 $button-shadow map-get($theme-colors, 'success');
  }

  &.btn-warning:hover,
  &.btn-outline-warning:hover {
    box-shadow: 0 0 $button-shadow map-get($theme-colors, 'warning');
  }

  &.btn-danger:hover,
  &.btn-outline-danger:hover {
    box-shadow: 0 0 $button-shadow map-get($theme-colors, 'danger');
  }

  &.btn-dark:hover,
  &.btn-outline-dark:hover {
    box-shadow: 0 0 $button-shadow map-get($theme-colors, 'dark');
  }

  &.btn-outline-dark:disabled:hover {
    color: map-get($theme-colors, 'dark') !important;
  }

  &.btn-info:hover,
  &.btn-outline-info:hover {
    box-shadow: 0 0 $button-shadow map-get($theme-colors, 'info');
  }

  &.btn-gray:hover,
  &.btn-outline-gray:hover {
    box-shadow: 0 0 $button-shadow map-get($theme-colors, 'gray');
  }

  &:hover {
    box-shadow: 0 15px 16px -9px rgba(33, 37, 41, 0.56) !important;
  }
}

.btn-group[role='group']:not(.btn-group-toggle) {
  .btn:first-of-type {
    border-top-left-radius: $large-border-radius !important;
    border-bottom-left-radius: $large-border-radius !important;
  }

  .btn:last-of-type {
    border-top-right-radius: $large-border-radius !important;
    border-bottom-right-radius: $large-border-radius !important;
  }
}

.dropdown.btn-icon {
  & > .btn {
    padding: 0.5rem 1rem;
    & > .icon:before {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }
}

.btn[class*='outline'].dashed {
  border-style: dashed;
  color: inherit;
}

.btn[class*='outline'] {
  color: $base-font-color;
  &:not(.btn-outline-gray):hover {
    color: #fff;
  }
}

.btn-group > .btn:first-child:not(.dropdown-toggle),
.btn-group > .btn-group:first-child > .btn {
  border-top-left-radius: $large-border-radius;
  border-bottom-left-radius: $large-border-radius;
}

.input-group-append,
.input-group-prepend {
  .btn {
    box-shadow: none;
    min-height: 100%;
  }
}

// toggle
.btn.input:not(.btn-sm) {
  padding: 0.8rem 0.5rem;
  min-height: 45px;
  line-height: 1;
}

input.form-control {
  min-height: 45px;
  max-height: 45px;
}

.form-control {
  &.is-invalid {
    border: 1px solid map-get($theme-colors, 'danger');
    border-radius: $default-border-radius;
    background-image: none;
  }
}
.toggl {
  &.is-invalid > div {
    border: 1px solid map-get($theme-colors, 'danger');
    border-radius: $default-border-radius;
    margin: -1px;
  }
}

/*
------------------ Toggle input ------------------
*/

.toggl {
  &.with-label {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      & > label {
        text-align: center;
      }
    }

    & > .btn-block {
      width: auto !important;
      flex: 1;
    }

    & > label {
      padding: 0.6rem 1rem;
      margin: 0;
    }
  }

  [class*='btn-outline-']:not(:hover) {
    background-color: map-get($theme-colors, 'white');
  }

  .btn-group {
    border-radius: $default-border-radius;

    [class*='btn-outline-'] {
      border-width: 0;
      &:not(:last-child) {
        margin-right: 2px;
      }
      &:hover {
        position: relative;
        &:before {
          content: '';
          background: rgba(0, 0, 0, 0.2);
          z-index: -1;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          top: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .btn-group {
      display: flex;
      flex-direction: column;

      [class*='btn-outline-'] {
        border-width: 0;
        &:not(:last-child) {
          margin-right: 0;
          border-right: none;
          border-bottom: 1px solid $gray-color;
        }
      }

      .btn:first-child:not(:last-child) {
        margin-left: -1px;
        border-radius: 0 !important;
        border-top-left-radius: $default-border-radius !important;
        border-top-right-radius: $default-border-radius !important;
      }

      .btn:last-child:not(:first-child) {
        border-radius: 0 !important;
        border-bottom-left-radius: $default-border-radius !important;
        border-bottom-right-radius: $default-border-radius !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .btn[class*='btn-outline-']:hover {
      color: inherit !important;
      background-color: #fff;
    }
  }
}

/*
------------------ Limit Text ------------------
*/

.limit-text {
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}

/*
--------------------- Badge ---------------------
*/

.badge {
  padding: 0.35rem 0.5rem;
  max-width: 100%;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1;
  align-items: center;
  text-overflow: ellipsis;
}

.badge.circle {
  border-radius: 100%;

  &.circle-sm {
    width: 1rem;
    height: 1rem;
    display: inline-block;
  }
}

.badge[class*='badge-'].link {
  color: map-get($theme-colors, 'white') !important;
}

/*
--------------------- Form ---------------------
*/

.form-group {
  margin-bottom: $grid-gutter-width / 2;
  flex: 1;
}

.form-label {
  font-weight: 400;
}

.form-control:not(textarea) {
  -webkit-appearance: none;
  border-right-width: 0;
  border-left-width: 0;
  border-top-width: 0;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: inherit;
}

.input-group-append {
  .btn {
    border-top-right-radius: $default-border-radius;
    border-bottom-right-radius: $default-border-radius;
  }
}

.invalid-feedback {
  text-align: left;
}

/*
--------------------- Toastr ---------------------
*/
#toast-container {
  margin-top: 1rem;
}

.toast {
  background-color: map-get($theme-colors, 'dark');
  .toast-message {
    a {
      color: map-get($theme-colors, 'dark') !important;
      text-decoration: underline;
    }
  }
}
.toast-success {
  background-color: map-get($theme-colors, 'success');
}
.toast-error {
  background-color: map-get($theme-colors, 'danger');
}
.toast-info {
  background-color: map-get($theme-colors, 'info');
}
.toast-warning {
  background-color: map-get($theme-colors, 'warning');
}

#toast-container > div {
  box-shadow: $default-shadow !important;

  &.toast-success {
    color: map-get($theme-colors, 'dark') !important;
  }
}

ul.pagination {
  margin-bottom: 0;
}

.input-group.is-invalid {
  ~ .invalid-feedback {
    display: block;
  }
}

/*
--------------------- Drodown ---------------------
*/
.btn-group > .btn:first-child:not(.dropdown-toggle),
.btn-group > .btn-group:first-child > .btn {
  border-top-left-radius: $default-border-radius;
  border-bottom-left-radius: $default-border-radius;
}

.btn-group > .btn:last-child:not(.dropdown-toggle),
.btn-group > .btn-group:last-child > .btn {
  border-top-right-radius: $default-border-radius;
  border-bottom-right-radius: $default-border-radius;
}

/*
--------------------- Deal filter ---------------------
*/

// Toggle
.form-control {
  .btn-group.btn-group-toggle {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
      display: flex;
      flex: 1;
    }
  }
}

.link:not(.btn):not(.dropdown-item):not(.navbar-brand):not(.nav-link) {
  color: map-get($theme-colors, 'primary');
  cursor: pointer;
}

// TODO: extract to search-deals folder
.deal-search-results {
  .deal-search-item {
    background-color: #fff;
    border-radius: $large-border-radius;
    box-shadow: $default-shadow;

    a.summary {
      text-decoration: none !important;
      display: flex;

      & > div {
        h3 {
          margin-bottom: 0;
          font-size: 20px;
        }

        & > small {
          color: map-get($theme-colors, 'dark') !important;
          font-size: 0.75rem;
          line-height: 1;
          display: block;
        }
      }
    }

    .deal-search-item__keywords,
    .deal-search-item__categories {
      font-size: 0.875rem;
      line-height: 1.3;
    }

    .deal-search-item__categories {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 500;
    }

    &:not(:last-of-type) {
      margin-bottom: $grid-gutter-width;
    }
  }
}

// TODO: extract to module
.contract-list-item,
.my-contract-item {
  border-radius: $large-border-radius;
  padding: $grid-gutter-width / 2;
  background: #fff;
  box-shadow: $default-shadow;
  overflow: hidden;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  .my-contract-item__table {
    margin-left: 0;
    margin-right: 0;

    [classˆ='col-'],
    [class*=' col-'] {
      padding: 0;

      h5 {
        font-weight: normal;
        font-size: 0.75rem;
        color: map-get($theme-colors, 'secondary');
        display: block;
        padding-bottom: $ui-padding / 4;
        border-bottom: 1px solid $gray-color;
      }
    }
  }

  .my-contract-item__footer {
    background: map-get($theme-colors, 'white');
    margin-left: $grid-gutter-width / -2;
    margin-right: $grid-gutter-width / -2;
    margin-bottom: $grid-gutter-width / -2;
    padding: $grid-gutter-width / 2;
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: row;
      & > * {
        flex: 1;
      }
    }
  }

  .my-contract-item__categories {
    @include media-breakpoint-down(sm) {
      & > * {
        max-width: 100%;
      }
    }
  }

  .my-contract-item__table {
    font-weight: 400;

    .col,
    [classˆ='col-'] {
      padding-left: 0;
    }

    .my-contract-item__table-header {
      border-bottom: 2px solid rgba(24, 24, 30, 0.1);
      color: map-get($theme-colors, 'secondary');
      font-size: 0.75rem;
    }
  }
}

// TODO: extract to Slider module
// RCSlider owerrides
.form-control.slider {
  border-bottom-width: 0;
  padding: 1.1rem 2rem !important;

  .rc-slider-handle {
    border-color: map-get($theme-colors, 'primary');

    border-radius: 11px;
    overflow: visible;
    display: block;
    padding: 5px;
    background-color: map-get($theme-colors, 'primary');
    color: #fff;
    height: auto !important;
    text-align: center;
    border: none;
    line-height: 1;
    font-size: 12px;
    width: 55px;
    margin-left: -29px;
    margin-top: -11px;
  }
  .rc-slider-track {
    background-color: map-get($theme-colors, 'primary');
  }
}

// TODO: fix search input
.control-search {
  position: relative;

  input {
    padding-right: 3rem;
    text-overflow: ellipsis;
  }

  .dropdown-item {
    padding: 0.25rem 0.5rem;
  }

  .dropdown-menu {
    padding: 0.25rem 0;
  }

  &::after {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: map-get($theme-colors, 'primary');

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    content: '\e915';
    z-index: 0;
    right: 1rem;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.select-input {
  @extend .form-control;
  padding: 0 !important;
  position: relative;
  cursor: pointer;
  display: flex;
  margin: 0;
  flex: 1;

  select {
    @extend .form-control;
    background: transparent !important;
    position: relative;
    min-height: 45px;
    max-height: 45px;
    z-index: 1;
  }

  & .caret {
    align-items: center;
    font-size: 0.875rem;
    position: absolute;
    display: flex;
    height: 100%;
    right: 1rem;
    z-index: 0;
    top: 0;
  }
}

.form-control {
  height: auto;
  line-height: 1;
  box-shadow: $default-shadow;
  border-width: 0;
  & {
    padding: 0.5rem 1rem;
  }
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none !important;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  box-shadow: $default-shadow;
}

.map-picker {
  border-radius: $default-border-radius;
  box-shadow: $default-shadow;
  overflow: hidden;
}

textarea {
  line-height: 1.5 !important;
  overflow: hidden;
  resize: none;
}

svg.logo {
  stroke-width: 0;

  .cls-1 {
    fill: map-get($theme-colors, 'dark');
  }
  .cls-2 {
    fill: map-get($theme-colors, 'primary');
  }

  &.light {
    .cls-1 {
      fill: #fff;
    }
  }
}

@include media-breakpoint-down(sm) {
  [classˆ='col-*'] {
    padding-right: 0;
  }
}

/*
-------------------- Bootstrap fix ----------------
*/

.navbar-nav .dropdown-menu {
  position: absolute;
}

.dropdown.d-flex {
  .dropdown-toggle {
    display: flex;
  }
}

/*
--------------------- Navbar ---------------------
*/

// Custom
.filter-panel {
  @extend .shadow-sm;
  border-radius: $default-border-radius;
}

@include media-breakpoint-up(md) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.65rem;
    padding-left: 0.65rem;
  }
}
/*
------------------- React bootstrap typeahead ------------------
*/

// TODO: get rid of this sh....

.rbt-input {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  .rbt-input-wrapper {
    .rbt-token {
      border-radius: $default-border-radius;

      background: map-get($theme-colors, 'primary');
      color: map-get($theme-colors, 'white');
      padding: 0.2rem 0.3rem;
      white-space: pre-line;
      display: inline-flex;
      align-items: center;
      font-size: 0.875rem;
      flex: 0;

      .close {
        border-left: 1px solid darken(map-get($theme-colors, 'primary'), 20);
        color: map-get($theme-colors, 'white');
        padding-left: 0.2rem;
        margin-left: 0.3rem;
        text-shadow: none;
      }
    }

    & > div:not(.rbt-token) {
      width: auto !important;
    }
  }

  &.is-valid.focus,
  &.is-invalid.focus {
    border-color: map-get($theme-colors, 'primary');
    box-shadow: 0 0 0 0.2rem transparentize(map-get($theme-colors, 'primary'), 0.75);
  }
}

ul {
  margin-left: 1rem;
  list-style: disc;
  margin-bottom: 0;
}

.pac-container {
  z-index: 9999;
}

.modal-body {
  background: $body-bg;
}

// Date picker

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__close-icon {
  width: 1.3rem;
  height: 1.3rem;
  &:after {
    background-color: map-get($theme-colors, 'primary');
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  &:hover {
    background-color: darken(map-get($theme-colors, 'primary'), 10%);
  }
  background-color: map-get($theme-colors, 'primary');
  color: #fff;
  border-radius: 5px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: lighten(map-get($theme-colors, 'primary'), 20%);
  &:hover {
    background-color: lighten(map-get($theme-colors, 'primary'), 10%);
  }
}

/* ------------------------ expand text ------------------------ */
.expand-text {
  max-width: 100%;
  overflow-x: hidden;

  .expand-text__more {
    display: none;
  }

  &.open {
    .expand-text__more {
      display: inline;
    }
    .expand-text__dots {
      display: none;
    }
  }
}

.grecaptcha-badge {
  position: relative;
  z-index: 3;
  display: none;
}

/* -------------------- MessagingInput ------------------ */
.messaging-input {
  select,
  input {
    box-shadow: none !important;
  }
}

/* ---------------------- Terms & Conditions --------------------- */
.terms-conditions-page {
  p,
  li {
    text-align: justify;
    margin-bottom: 1rem;
    line-height: 1.8;
  }
  li {
    padding-left: 0.5rem;
  }
  ol {
    list-style: decimal;
    margin-left: 2rem;
  }
  ul {
    list-style: disc;
    margin-left: 2rem;
  }
  h2 {
    margin-bottom: 2rem;
    text-align: center;
    margin-top: 4rem;
  }
  h1,
  h2 {
    display: block;
  }

  h2 {
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    ol,
    ul {
      margin-left: 1rem;
    }
  }

  li > ol > li {
    list-style: lower-alpha;
  }
}

.dropdown-menu {
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.1);
  border-width: 0 !important;
  overflow: hidden;
  .dropdown-item {
    padding: 0.5rem 1rem;
  }
}

/* ------------------------------ ------------------------------ */
.join-banner {
  background-image: url('_assets/images/join-banner-bg.png');
  border-radius: $large-border-radius;
  border-bottom-right-radius: 0;
  background-size: cover;
}

// Table
table {
  font-size: 0.75rem;

  th {
    font-weight: 500;
    vertical-align: top;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }

  * {
    border-color: map-get($theme-colors, 'primary') !important;
  }

  th,
  td {
    text-align: right;
  }

  tfoot {
    background: lighten(map-get($theme-colors, 'primary'), 35);
    font-weight: 500;
  }
}

// succes story graph

.graph__wrapper {
  display: flex;
  flex-flow: row wrap;
  flex: 1 100%;
  margin-bottom: 2rem;

  & > * {
    flex: 1 100%;
  }

  .graph-rows {
    & > * {
      white-space: nowrap;
      padding-right: 1rem;
      padding-top: 2.5rem;
      text-align: right;
      font-size: 1rem;
      line-height: 1rem;
    }
    flex: 1 0 0;
  }

  .graph-data {
    justify-content: space-around;
    flex-direction: row;
    position: relative;
    display: flex;
    flex: 7 0px;

    hr {
      background: map-get($theme-colors, 'primary');
      position: absolute;
      border-width: 0;
      opacity: 0.3;
      width: 100%;
      height: 1px;
      z-index: 0;
      padding: 0;
      margin: 0;
      left: 0;
      margin-top: -0.5rem;
    }

    & > * {
      justify-content: space-around;
      justify-content: flex-end;
      flex-direction: column;
      position: relative;
      display: flex;
      flex: 1 1rem;

      .graph-bar-bar {
        background: map-get($theme-colors, 'primary');
        position: absolute;
        bottom: 0.5rem;
        width: 60%;
        left: 20%;
      }

      .graph-bar-label {
        position: absolute;
        text-align: center;
        bottom: -2rem;
        width: 100%;

        @include media-breakpoint-down(sm) {
          font-size: 0.7rem;
        }
      }
    }
  }
}

.alert.alert-danger {
  a {
    color: map-get($theme-colors, 'danger') !important;
  }
}
