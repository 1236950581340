.container.layout-common {
  flex-direction: column;
  padding: $ui-padding * 2.5 $ui-padding;
  display: flex;
  flex: 1;

  .layout-common__content {
    flex-direction: column;
    display: flex;
    flex: 1;

    @include media-breakpoint-up(xs) {
      &.narrow {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @include media-breakpoint-up(sm) {
      &.narrow {
        padding-left: $ui-padding * 1.5;
        padding-right: $ui-padding * 1.5;
      }
    }

    @include media-breakpoint-up(md) {
      &.narrow {
        padding-left: $ui-padding * 3;
        padding-right: $ui-padding * 3;
      }
    }

    @include media-breakpoint-up(lg) {
      &.narrow {
        padding-left: $ui-padding * 6;
        padding-right: $ui-padding * 6;
      }
    }
  }
}
