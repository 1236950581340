.form-conrol.image {
  justify-content: center;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding-bottom: 75%;
  display: block;

  align-items: center;
  .image-wrapper {
    border-radius: $default-border-radius;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: $default-shadow;
    border: 3px solid #fff;
    justify-content: center;
    background-size: contain;
    flex-direction: column;
    align-items: center;
    position: absolute;

    overflow: hidden;
    cursor: pointer;
    max-height: 100%;
    display: flex;
    height: 100%;
    width: 100%;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background: inherit;
      background-size: cover;
    }

    &:before {
      filter: blur(10px);
      -webkit-filter: blur(10px);
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
    }

    &:after {
      background-size: contain;
    }

    & > .lds-continer {
      position: absolute;
      top: 0;
    }
    & > .placeholder {
      justify-content: center;
      align-items: center;
      position: absolute;
      display: flex;
      height: 100%;
      top: 0;
    }
  }
}
