@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?n32k9w');
  src: url('fonts/icomoon.eot?n32k9w#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?n32k9w') format('truetype'),
    url('fonts/icomoon.woff?n32k9w') format('woff'),
    url('fonts/icomoon.svg?n32k9w#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: '\e900';
}
.icon-caret-up:before {
  content: '\e902';
}
.icon-caret-down:before {
  content: '\e907';
}
.icon-caret-right:before {
  content: '\e903';
}
.icon-caret-left:before {
  content: '\e90a';
}
.icon-close:before {
  content: '\e904';
}
.icon-plus:before {
  content: '\e905';
}
.icon-icq:before {
  content: '\e918';
  color: #7ebd00;
}
.icon-linkedin:before {
  content: '\e919';
  color: #0077b5;
}
.icon-telegram:before {
  content: '\e91a';
  color: #2ca5e0;
}
.icon-signal:before {
  content: '\e91b';
  color: #2592e9;
}
.icon-whatsapp:before {
  content: '\e91c';
  color: #25d366;
}
.icon-skype:before {
  content: '\e91d';
  color: #00aff0;
}
.icon-facebook:before {
  content: '\e901';
  color: #3b5998;
}
.icon-arrow-down:before {
  content: '\e908';
}
.icon-arrow-up:before {
  content: '\e90c';
}
.icon-arrow-right:before {
  content: '\e909';
}
.icon-arrow-left:before {
  content: '\e912';
}
.icon-check:before {
  content: '\e90d';
}
.icon-chevron-down:before {
  content: '\e90e';
}
.icon-chevron-left:before {
  content: '\e90f';
}
.icon-chevron-right:before {
  content: '\e910';
}
.icon-chevron-up:before {
  content: '\e911';
}
.icon-offer:before {
  content: '\e913';
}
.icon-request:before {
  content: '\e914';
}
.icon-unseen:before {
  content: '\e916';
}
.icon-seen:before {
  content: '\e917';
}
.icon-search:before {
  content: '\e915';
}
.icon-SHARE_ASSETS .path1:before {
  content: '\e91f';
  color: rgb(71, 60, 239);
}
.icon-SHARE_ASSETS .path2:before {
  content: '\e92c';
  margin-left: -1em;
  color: rgb(247, 202, 40);
  opacity: 0.8;
}
.icon-SHARE_ASSETS .path3:before {
  content: '\e92d';
  margin-left: -1em;
  color: rgb(33, 37, 41);
  opacity: 0.8;
}
.icon-SPARE_CAPACITY .path1:before {
  content: '\e921';
  color: rgb(71, 60, 239);
}
.icon-SPARE_CAPACITY .path2:before {
  content: '\e92e';
  margin-left: -1em;
  color: rgb(247, 202, 40);
  opacity: 0.8;
}
.icon-SPARE_CAPACITY .path3:before {
  content: '\e92f';
  margin-left: -1em;
  color: rgb(33, 37, 41);
  opacity: 0.8;
}
.icon-SHARE_PROCUREMENT .path1:before {
  content: '\e920';
  color: rgb(71, 60, 239);
}
.icon-SHARE_PROCUREMENT .path2:before {
  content: '\e930';
  margin-left: -1em;
  color: rgb(246, 211, 83);
  opacity: 0.8;
}
.icon-RND_COOPERATION .path1:before {
  content: '\e922';
  color: rgb(71, 60, 239);
}
.icon-RND_COOPERATION .path2:before {
  content: '\e931';
  margin-left: -1em;
  color: rgb(24, 24, 30);
  opacity: 0.8;
}
.icon-RND_COOPERATION .path3:before {
  content: '\e932';
  margin-left: -1em;
  color: rgb(246, 211, 83);
  opacity: 0.8;
}
.icon-viber:before {
  content: '\e924';
  color: #665cac;
}
.icon-trash-can:before {
  content: '\e925';
}
.icon-clipboard-list:before {
  content: '\e926';
}
.icon-clipboard-edit:before {
  content: '\e927';
}
.icon-user:before {
  content: '\e928';
}
.icon-filter:before {
  content: '\e929';
}
.icon-page:before {
  content: '\e91e';
}
.icon-cooperation:before {
  content: '\e923';
}
.icon-envelope:before {
  content: '\e92a';
}
.icon-location:before {
  content: '\e92b';
}
.icon-quote:before {
  content: '\e906';
}
